<script setup lang="ts">
import type { TopicPreview } from '~/integrations/resources/sanityTopicToTopicPreview';

const props = withDefaults(
  defineProps<{
    index: number;
    isGalleryView?: boolean;
    isSmall?: boolean;
    topicPreview: TopicPreview;
  }>(),
  {
    isGalleryView: false,
    isSmall: false,
  }
);

const { $urlFor, $viewport } = useNuxtApp();
const localePath = useLocalePath();

const imageUrl = computed(() => {
  if (!props.topicPreview.featuredImageObject) {
    return '';
  }

  return $urlFor(props.topicPreview.featuredImageObject).url();
});
</script>

<template>
  <NuxtLink
    :to="localePath(`/topics/${topicPreview.idSlug}/${topicPreview.slug}`)"
    class="topic-card topic-card flex items-end justify-start bg-cover bg-center p-4 hover:no-underline lg:p-6"
    :class="{
      'small-card': isSmall,
      'default-card': !isSmall,
      'large-feature-gallery-card': isGalleryView && index === 0,
      'horizontal-feature-gallery-card':
        (isGalleryView && $viewport.breakpoint.value === 'xs' && index === 4) ||
        ($viewport.breakpoint.value !== 'xs' && index === 5),
    }"
    :style="`background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)), url(${imageUrl})`"
  >
    <span class="sr-only">{{ topicPreview.name }}</span>
    <h3 class="h2--no-border my-0 py-0 font-bold text-white">
      {{ topicPreview.name }}
    </h3>
  </NuxtLink>
</template>

<style lang="css" scoped>
.topic-card {
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
}

.small-card {
  @apply min-h-30 items-center;
}
.small-card h3,
.default-card h3 {
  @apply text-sm md:text-base lg:text-lg;
}

.default-card {
  @apply col-span-1 min-h-30 sm:min-h-36 md:min-h-44 lg:min-h-60;
}

.large-feature-gallery-card {
  @apply col-span-2 xs:row-span-2;
}
.horizontal-feature-gallery-card {
  @apply col-span-2 xs:col-start-2 xs:row-span-2 sm:col-start-auto sm:row-span-1;
}

.large-feature-gallery-card h3,
.horizontal-feature-gallery-card h3 {
  @apply text-2xl leading-snug md:text-2.2xl;
}
</style>
